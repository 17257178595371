// TR
export const locale = {
  validation: {
    required: "Bu alanın doldurulması zorunludur.",
    email: "Lütfen geçerli bir e-posta adresi giriniz.",
    numeric: "Lütfen geçerli bir sayı giriniz.",
    minLength: "Lütfen en az {min} karakter uzunluğunda bir değer giriniz.",
    maxLength: "Lütfen en fazla {max} karakter uzunluğunda bir değer giriniz.",
    minValue: "Lütfen {min} değerine eşit ya da daha büyük bir değer giriniz.",
    maxValue: "Lütfen {max} değerine eşit ya da daha küçük bir değer giriniz.",
    between: "Lütfen {min} ile {max} arasında bir değer giriniz.",
    sameAs: "Lütfen aynı değeri tekrar giriniz.",
    countryCode: "Lütfen ülke kodunuzu seçiniz.",
    uniqueEmail: "Bu email kullanılmaktadır.",
    uniquePhone: "Bu telefon numarası kullanılmaktadır.",
    uniqueCardNumber: "Bu kart numarası kullanılmaktadır.",
    isHour: "Lütfen doğru formatta giriniz.",
    isPhone: "Lütfen geçerli bir telefon numarası giriniz.",
    integer: "Lütfen bir tamsayı giriniz.",
    threeChrForSearch: "Aramak için en az 3 karakter giriniz.",
    needToConfirmPrivacyAndPolicy: "Kullanım ve Gizlilik Koşullarını kabul etmelisiniz",
  },
  common: {
    login: 'Giriş Yap',
    email: "Email",
    phone: "Telefon",
    save: "Kaydet",
    ok: "Tamam",
    dontSave: "Kaydetme",
    delete: "Sil",
    restore: "Kurtar",
    cancel: "Vazgeç",
    close: "Kapat",
    abort: "İptal Et",
    done: "Tamam",
    next: "İleri",
    prev: "Geri",
    continue: "Devam",
    status: "Durum",
    action: "İşlem",
    address: "Adres",
    add: "Ekle",
    drop: "Çıkar",
    edit: "Düzenle",
    description: "Açıklama",
    notes: "Notlar",
    note: "Not",
    other: "Diğer",
    active: "Aktif",
    inactive: "İnaktif",
    reset: "Sıfırla",
    loading: 'Yükleniyor',
    load: "Yükle",
    name: "İsim",
    account: "Hesap",
    features: "Özellikler",
    history: "Kayıtlar",
    support: "Destek",
    signOut: "Çıkış Yap",
    days: {
      Day: "Gün",
      days: "Günler",
      mon: "Pazartesi",
      tue: "Salı",
      wed: "Çarşamba",
      thu: "Perşembe",
      fri: "Cuma",
      sat: "Cumartesi",
      sun: "Pazar"
    },
    permission: "İzin",
    permissions: "İzinler",
    start: "Başlat",
    end: "Bitir",
    session: "Seans",
    optional: "(İsteğe Bağlı)",
    selectDuration: "Periyot Seçiniz",
    searchFilter: "Arama Filtresi",
    duration: "Süre",
    all: "Hepsi",
    yes: "Evet",
    no: "Hayır",
    firstName: "Ad",
    lastName: "Soyad",
    gender: "Cinsiyet",
    male: "Erkek",
    female: "Kadın",
    birthDay: "Doğum Günü",
    startDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    startTime: "Başlangıç Saati",
    endTime: "Bitiş Saati",
    type: "Tür",
    total: "Toplam",
    summary: "Özet",
    date: "Tarih",
    dueDate: "Son tarih",
    "treeSelectLimit": "{count} tane daha",
    "treeSelectNoOption": "Hiç seçenek yok.",
  },
  "notification": {
    "items": {
      book: 'Kitap',
      author: 'Yazar',
      "category": "Kategori",
      booking: 'Rezervasyon',
      comment: 'Yorum',
      rating: 'Puan',
      wish: 'İstek',
      profile: 'Profil',
      password: 'Şifre',
      user: 'Kullanıcı',
      "item": "Ürün"
    },
    "messages": {
      "success": "Başarılı!",
      "error": "Hata!",
      "createSuccess": "{item} başarıyla oluşturuldu.",
      "createError": "{item} oluşturulamadı, bir hata oluştu.",
      "updateSuccess": "{item} başarıyla güncellendi.",
      "updateError": "{item} güncellenemedi, bir hata oluştu.",
      "deleteSuccess": "{item} başarıyla silindi.",
      "deleteError": "{item} silinemedi, bir hata oluştu.",
      "forbiddenSection": "Bu alana girmeye izniniz yok!",
      "forbiddenAction": "Bu işlemi yapmaya yetkiniz yok!",
      "validationError": "Alanları doğru bir şekilde doldurmanız gerelmektedir!",
      "wrongCredentials": "Kullanıcı adınız veya şıfreniz yanlış!",
      "selectPaymentMethod": "Ödeme yöntemini seçiniz.",
      "loginNotAllowed": "Sisteme giriş izniniz yok!",
      "oldPasswordWrong": "Girdiğiniz eski şifre yanlış!",
      "somethingWentWrong": "İşlem esnasında hata meydana geldi!",
      "borrowSuccessful": "Kitap başarıyla ödünç alındı!",
      "addToWishSuccessful": "Kitap başarıyla istek listesine eklendi!",
      "authorHasBook": "Yazarın sistemde kayıtlı kitabı olduğu için silinemedi!",
      "categoryHasBook": "Bu kategoriye ait sistemde kayıtlı kitabı olduğu için silinemedi!",
      "emailAlreadyUsed": "Bu eposta adresi daha önce kullanılmış!",
      "phoneAlreadyUsed": "Bu telefon numarası daha önce kullanılmış!",
      "confirmationCodeNotCorrect": "Doğrulama kodunu yanlış girdiniz!",
      "noSuchAUserHavingPhone": "Bu telefon numarasına sahip kullanıcı bulunamadı!",
      "extensionSuccessful": "Kitap iade süresi başarılı bir şekilde uzatıldı!",
    },
    "swal": {
      "confirmDelete": "Emin misiniz?",
      "confirmDeleteDesc": "Bunu geri alamazsınız!",
      "saveChanges": "Değişiklikler kaydedilsin mi?",
      "saveChangesDesc": "Kaydedilmemiş bilgilerin silinecek! <br /> Kapatmadan önce kaydedilsin mi?",
    }
  },
  project: {
    selectCategory: "Kategori Seç",
    searchBookName: "Kitap Adı Ara",
    search: "Ara",
    title: "Başlık",
    author: "Yazar",
    authors: "Yazarlar",
    category: "Kategori",
    categories: "Kategoriler",
    publisher: "Yayın Evi",
    publishDate: "Yayın Tarihi",
    status: "Durum",
    transaction: "İşlem",
    onTheShelf: "Rafta",
    outOfTheShelf: "Rafta Değil",
    details: "Detaylar",
    comments: "Yorumlar",
    noComments: "Yorum yok",
    rating: "Değerlendirme",
    noRatings: "Değerlendirme yok",
    book: "Kitap",
    bookName: "Kitap Adı",
    books: "Kitaplar",
    borrow: "Ödünç al",
    borrowConfirm: "Kitabı ödünç almak istediğinize emin misiniz?",
    addToWishlist: "İstek listesine ekle",
    member: "Üye",
    profileOverview: "Kişisel Bilgiler",
    changePassword: "Şifre Değiştir",
    canChangePassword: "Şifrenizi değiştirebilirsiniz",
    bookHistory: "Kitap Geçmişi",
    wishList: "İstek Listesi",
    wishListConfirm: "Kitabı istek listesine eklemek itediğinize emin misiniz?",
    wishListInfo: "İstek listenizdeki kitapları takip edebilirsiniz",
    personalInformation: "Kişisel Bilgiler",
    canUpdatePersonalInformation: "Kişisel bilgilerinizi güncelleyebilirsiniz",
    saveChanges: "Değişiklikleri kaydet",
    confirm: "Onayla",
    cancel: "İptal",
    avatar: "Avatar",
    selectAvatar: "Avatar Seç",
    firstName: "Ad",
    lastName: "Soyad",
    email: "Email Adresi",
    currentPassword: "Güncel Şifre",
    newPassword: "Yeni Şifre",
    password: "Şifre",
    reNewPassword: "Şifre-tekrar",
    bookHistoryInfo: "Aldığınız kitapları görebilir ve iade edebilirsiniz",
    receivedDate: "Alış tarih",
    returnedDate: "İade tarihi",
    borrowed: "Ödünç Alındı",
    returned: "İade Edildi",
    extended: "Süresi Uzatıldı",
    return: "İade et",
    returnConfirm: "Kitabı iade etmek istediğinize emin misiniz?",
    extendConfirm: "Kitabın iade tarihini uzatmak istediğinize emin misiniz?",
    comment: "Yorum",
    toComment: "Yorum yap",
    noRecord: "Kayıt bulunamadı",
    noNotification: "Bildirim yok",
    remove: "Sil",
    removeConfirm: "Kitabı silmek istediğinize emin misiniz?",
    profile: "Profil",
    library: "Kütüphane",
    admin: "Yönetici",
    administration: "Yönetim",
    adminPanel: "Admin Paneli",
    users: "Kullanıcılar",
    bookList: "Kitap Listesi",
    shelf: "Raf",
    barcode: "Barkod",
    bookTransactions: "Kitap İşlemleri",
    categoryTransactions: "Kategori İşlemleri",
    authorTransactions: "Yazar İşlemleri",
    adminTransactions: "Admin İşlemleri",
    userTransactions: "Kullanıcı İşlemleri",
    commentTransactions: "Yorum İşlemleri",
    description: "Açıklama",
    add: "Ekle",
    addUser: "Kullanıcı Ekle",
    addBook: "Kitap Ekle",
    newAuthor: "Yeni Yazar",
    newCategory: "Yeni Kategori",
    edit: "Düzenle",
    categoryList: "Kategori Listesi",
    addCategory: "Kategori Ekle",
    categoryName: "Kategori Adı",
    authorList: "Yazar Listesi",
    addAuthor: "Yazar Ekle",
    authorName: "Yazar Adı",
    adminList: "Admin Listesi",
    user: "Kullanıcı",
    editor: "Editör",
    userList: "Kullanıcı Listesi",
    role: "Görev",
    pendingComments: "Bekleyen Yorumlar",
    approvedComments: "Onaylanan Yorumlar",
    approve: "Onayla",
    myLibrary: "Benim Kütüphanem",
    aboutUs: "Hakkımızda",
    contact: "İletişim",
    logOut: "Çıkış Yap",
    notifications: "Bildirimler",
    bookWaiting: "Kitap sizi bekliyor!",
    bookRequest: "Kitap İsteği",
    login: "Giriş Yap",
    loginFirst: "Kitap ödünç almak için giriş yapmalısınız",
    selectBookWithNewSys1: "Câmiakademi Kütüphane'si",
    selectBookWithNewSys2: "kullanıcı paneline hoş geldiniz.",
    welcome: "Hoşgeldiniz",
    areYouNew: "Yeni misiniz?",
    createAnAccount: "Hesap oluştur",
    forgetPassword: "Şifrenizi mi unuttunuz?",
    register: "Kaydol",
    enterDetailsToCreate: "Hesabı oluşturmak için bilgilerinizi giriniz",
    enterPhoneToResetPassword: "Şifrenizi sıfırlamak için telefon numaranızı giriniz",
    send: "Gönder",
    ok: "Tamam",
    qrCode: "QR Kodum",
    messages: {
      emailRequired: "Email adresi gerekli!",
      emailValid: "Lütfen geçerli email adresi giriniz!",
      passwordRequired: "Şifre gerekli!",
      passwordsNotMatched: "Şifreler uyuşmuyor!",
      rePasswordRequired: "Şifre-tekrar gerekli!",
      firstNameRequired: "Ad gerekli!",
      lastNameRequired: "Soyad gerekli!",
      sureToFillFields: "Lütfen tüm alanları eksiksiz doldurduğunuzdan emin olun"
    }
  }
};
