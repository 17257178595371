export const LoanStatusTypes = {
  LOANED: 1,
  RETURNED: 2,
  EXTENDED: 3,
};

export const BookStatusTypes = {
  AVAILABLE: 1,
  LOANED: 2
};

export const UserTypes = {
  ADMIN: 1,
  EDITOR: 2,
  USER: 3,
};
